import React, {useState} from "react";
import Spinner from "../../shared/Spinner";
import {useNavigate} from "react-router-dom";
import {Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography,} from "@mui/material";
import {useDeleteMemberMutation, useGetAllMembersQuery,} from "../../services/membersApi";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {toast} from "react-toastify";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Visibility} from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PeopleIcon from "@mui/icons-material/People";
import EditIcon from "@mui/icons-material/Edit";
import {flattenObject} from "../../utils/sharedFunctions";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MemberManager = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              padding: "25px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Member Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user.email",
      label: "Added By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "addDate",
      label: "Date Added",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "editDate",
      label: "Date Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="View Member">
                <IconButton
                  onClick={() =>
                    navigate("/members/view/" + tableMeta.rowData[0])
                  }
                >
                  <Visibility color={"primary"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Member">
                <IconButton
                  onClick={() =>
                    navigate("/members/edit/" + tableMeta.rowData[0])
                  }
                >
                  <EditIcon color={"warning"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Member">
                <IconButton
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        deleteMemberFromDB(tableMeta.rowData[0]);
                      },
                    });
                  }}
                >
                  <DeleteForeverIcon color={"error"} />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => (
    <>
      <Tooltip title="Add Member">
        <IconButton onClick={() => navigate("/members/add")}>
          <PeopleIcon color={"warning"} sx={{ fontSize: { xs: 26, sm: 32 } }} />
        </IconButton>
      </Tooltip>
    </>
  );

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
    rowsPerPage: 10,
    resizableColumns: false,
    rowsPerPageOptions: [5, 10, 15, 25, 50, 100, 150],
    jumpToPage: true,
    showLastButton: true,
    elevation: 2,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total Per Page",
        displayRows: "of",
        jumpToPage: "Page",
      },
      body: {
        noMatch: "No Members to display please Add Member",
      },
    },
    selectableRows: "none",
    customToolbar: () => <HeaderElements />,
  };
  let Content;
  const { data, isLoading, isError, error, isSuccess } =
    useGetAllMembersQuery();
  const [deleteMember] = useDeleteMemberMutation();

  if (isLoading) {
    return <Spinner />;
  }
  if (isSuccess && data) {
    let arrayValues = data.map((_data) => {
      return flattenObject(_data);
    });
    // console.log(arrayValues);
    let TableData = arrayValues.map((row) => {
      const updatedRow = {
        ...row,
        addDate: moment(row.createdAt).format("LLLL"),
        editDate: moment(row.updatedAt).format("LLLL"),
      };
      return updatedRow;
    });
    Content = (
      <Box
        sx={{
          width: { xs: "98%", sm: "95%", md: "90%", lg: "85%", xl: "80%" },
          margin: "auto auto",
          marginTop: "1.5%",
          marginBottom: "2%",
        }}
      >
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={"Members Manager"}
              data={TableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>

        <Dialog open={confirmDialog.isOpen}>
          <DialogContent>
            <Typography variant="h6">{confirmDialog.title}</Typography>
            <Typography variant="subtitle2" color={"indigo"}>
              {confirmDialog.subTitle}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
            <Button
              variant={"contained"}
              color={"error"}
              onClick={confirmDialog.onConfirm}
              size={"small"}
            >
              Yes
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={() =>
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  if (isError && error) {
    Content = (
      <>
        <Box className={classes.root}>
          <Grid
            style={{
              padding: "50px 5px 0 5px",
              width: 350,
              margin: "0 auto",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} mb={0} className={classes.items}>
                <PeopleIcon
                  color={"primary"}
                  style={{ width: "70px", height: "70px" }}
                />
              </Grid>
              <Grid item xs={12} mb={2} mt={5} className={classes.items}>
                <Typography variant="h5" color="error">
                  No Members to show !
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} mt={2} mb={1}>
              <Grid item xs={12}>
                <Button
                  type="button"
                  size={"large"}
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/members/add")}
                  fullWidth
                  startIcon={<PeopleIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Add Member
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  const deleteMemberFromDB = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await deleteMember(id)
      .unwrap()
      .then((payload) => {
        toast.success(`Successfully deleted Member ${JSON.stringify(payload)}`);
        navigate("/members");
      })
      .catch((error) =>
        toast.error(`Error deleting Member ${error.data.message}`)
      );
  };

  return <>{Content}</>;
};

export default MemberManager;

import React, {useState} from "react";
import {Box, Button, Grid, InputAdornment, TextField, Typography,} from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {useAddIncomeCategoryMutation} from "../../services/incomeCategoryApi";
import {toast} from "react-toastify";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddIncomeCategory = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    income_type: "",
  });
  const { income_type } = formData;
  const [addIncomeCategory, { isError, error }] =
    useAddIncomeCategoryMutation();

  if (isError && error) {
    toast.error("Error adding Incomecategory");
    console.log(JSON.stringify(error.message));
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    await addIncomeCategory({ income_type })
      .unwrap()
      .then((payload) => {
        toast.success(`Income Category added successfully`);
        navigate("/incomecategory");
      })
      .catch((error) =>
        toast.error(`Error adding Income Category ${error.data.message}`)
      );
    navigate("/incomecategory");
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Box className={classes.root}>
        <Grid
          style={{
            padding: "50px 5px 0 5px",
            width: 350,
            margin: "0 auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} mb={0} className={classes.items}>
              <HowToRegIcon
                color={"primary"}
                style={{ width: "70px", height: "70px" }}
              />
            </Grid>
            <Grid item xs={12} mb={2} className={classes.items}>
              <Typography variant="h5" color="error">
                Add Income Category
              </Typography>
            </Grid>
          </Grid>
          <form onSubmit={onSubmit}>
            <Grid container spacing={1}>
              <Grid xs={12} sm={12} mb={1} item>
                <TextField
                  name="income_type"
                  type="text"
                  onChange={onChange}
                  label="Income Type"
                  value={income_type}
                  placeholder="Enter Income Type"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2} mb={1}>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  size={"large"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<LockOpenIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Submit
                </Button>
                <Button
                  size={"large"}
                  variant="contained"
                  color={"success"}
                  fullWidth
                  onClick={() => navigate(-1)}
                  startIcon={<HowToRegIcon />}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Box>
    </>
  );
};

export default AddIncomeCategory;

import React from "react";
import {useGetSingleIncomeCategoryQuery} from "../../services/incomeCategoryApi";
import Spinner from "../../shared/Spinner";
import {useParams} from "react-router-dom";

const ViewIncomeCategory = () => {
  const params = useParams();
  let Content;
  const { data, isLoading, isError, error, isSuccess, message } =
    useGetSingleIncomeCategoryQuery(params.id);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError && error) {
    Content = (
      <div>
        <p>IncomeCategory not found... {JSON.stringify(message)}</p>
      </div>
    );
  }
  if (isSuccess && data) {
    Content = <div>{JSON.stringify(data)}</div>;
  }

  return Content;
};

export default ViewIncomeCategory;

import {apiSlice} from "../app/apiSlice";

// getAllIncomeCategory,
// getSingleIncomeCategory,
// setIncomeCategory,
// updateIncomeCategory,
// deleteIncomeCategory,

export const incomeCategoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllIncomeCategory: builder.query({
      query: () => "/incomecategory",
      providesTags: ["IncomeCategory"],
    }),
    getSingleIncomeCategory: builder.query({
      query: (id) => `/incomecategory/${id}`,
      providesTags: ["IncomeCategory"],
    }),
    addIncomeCategory: builder.mutation({
      query: (incomeCategory) => ({
        url: "/incomecategory",
        method: "POST",
        body: incomeCategory,
      }),
      invalidatesTags: ["IncomeCategory"],
    }),
    deleteIncomeCategory: builder.mutation({
      query: (id) => ({
        url: `/incomecategory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["IncomeCategory"],
    }),
    updateIncomeCategory: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/incomecategory/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["IncomeCategory"],
    }),
  }),
});

export const {
  useGetAllIncomeCategoryQuery,
  useGetSingleIncomeCategoryQuery,
  useAddIncomeCategoryMutation,
  useDeleteIncomeCategoryMutation,
  useUpdateIncomeCategoryMutation,
} = incomeCategoryApi;

import React, {useEffect, useState} from "react";
import {useAddExpenseMutation} from "../../services/expenseApi";
import {useGetAllExpenseCategoryQuery} from "../../services/expenseCategoryApi";
import {useGetAllMembersQuery} from "../../services/membersApi";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {makeStyles} from "@mui/styles";
import {Box, Button, Grid, InputAdornment, TextField, Typography,} from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PaidIcon from "@mui/icons-material/Paid";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddExpense = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    amount: null,
  });
  const [expenseCategory, setExpenseCategory] = useState(null);
  const [member, setMember] = useState(null);
  const [date, setDate] = useState(null);
  const { data: expenseCategoryData, isSuccess: icIsSuccess } =
    useGetAllExpenseCategoryQuery();
  const { data: memberData, isSuccess: mIsSuccess } = useGetAllMembersQuery();
  const { amount } = formData;
  let Content;
  const [addExpense] = useAddExpenseMutation();

  const onSubmit = async (e) => {
    e.preventDefault();
    let expense_type = expenseCategory;
    await addExpense({ date, expense_type, amount, member })
      .unwrap()
      .then((payload) => {
        toast.success(`Expense added successfully`);
        navigate("/expense");
      })
      .catch((error) =>
        toast.error(`Error adding Expense : ${error.data.message}`)
      );
  };

  useEffect(() => {
    function fetchData() {
      if (icIsSuccess && expenseCategoryData) {
        setExpenseCategory(expenseCategoryData.expense_type);
      }
      if (mIsSuccess && memberData) {
        setMember(memberData.member);
      }
    }
    fetchData();
  }, [icIsSuccess, mIsSuccess, expenseCategoryData, memberData]);

  let updateInputDate = (e) => {
    setDate(e);
  };
  const handleExpenseCategoryChange = (event) => {
    setExpenseCategory(event.target.value);
  };
  const handleMemberChange = (event) => {
    setMember(event.target.value);
  };
  let updateInputExpense = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  if (!memberData) {
    Content = (
      <>
        <Box className={classes.root}>
          <Grid
            style={{
              padding: "50px 5px 0 5px",
              width: 350,
              margin: "0 auto",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} mb={0} className={classes.items}>
                <ShoppingCartCheckoutIcon
                  color={"primary"}
                  style={{ width: "70px", height: "70px" }}
                />
              </Grid>
              <Grid item xs={12} mb={2} mt={5} className={classes.items}>
                <Typography variant="h5" color="error">
                  No Member found please add !
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} mt={2} mb={1}>
              <Grid item xs={12}>
                <Button
                  type="button"
                  size={"large"}
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/members/add")}
                  fullWidth
                  startIcon={<ShoppingCartCheckoutIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Add Member
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  } else if (!expenseCategoryData) {
    Content = (
      <>
        <Box className={classes.root}>
          <Grid
            style={{
              padding: "50px 5px 0 5px",
              width: 350,
              margin: "0 auto",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} mb={0} className={classes.items}>
                <ShoppingCartCheckoutIcon
                  color={"primary"}
                  style={{ width: "70px", height: "70px" }}
                />
              </Grid>
              <Grid item xs={12} mb={2} mt={5} className={classes.items}>
                <Typography variant="h5" color="error">
                  No Expense Category found please add !
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} mt={2} mb={1}>
              <Grid item xs={12}>
                <Button
                  type="button"
                  size={"large"}
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/expensecategory/add")}
                  fullWidth
                  startIcon={<ShoppingCartCheckoutIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Add Expense Category
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  } else {
    Content = (
      <>
        <Box className={classes.root}>
          <Grid
            style={{
              padding: "50px 5px 0 5px",
              width: 350,
              margin: "0 auto",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} mb={0} className={classes.items}>
                <ShoppingCartCheckoutIcon
                  color={"primary"}
                  style={{ width: "70px", height: "70px" }}
                />
              </Grid>
              <Grid item xs={12} mb={2} className={classes.items}>
                <Typography variant="h5" color="error">
                  Add Expense
                </Typography>
              </Grid>
            </Grid>
            <form onSubmit={onSubmit}>
              <Grid container spacing={1}>
                <Grid xs={12} sm={12} mb={1} item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Date"
                      value={date}
                      onChange={updateInputDate}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" shrink={true}>
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={expenseCategory}
                        label="Type"
                        onChange={handleExpenseCategoryChange}
                      >
                        {expenseCategoryData.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.expense_type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" shrink={true}>
                        Member
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={member}
                        label="Member"
                        onChange={handleMemberChange}
                      >
                        {memberData.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <TextField
                    name="amount"
                    type="number"
                    onChange={updateInputExpense}
                    label="Amount"
                    value={amount}
                    placeholder="Enter Amount"
                    fullWidth
                    required
                    variant={"outlined"}
                    InputProps={{
                      // classes: { input: classes.input1 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <PaidIcon color={"success"} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} mt={2} mb={1}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    size={"large"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<ShoppingCartCheckoutIcon />}
                    style={{ marginBottom: "15px" }}
                  >
                    Submit
                  </Button>
                  <Button
                    size={"large"}
                    variant="contained"
                    color={"success"}
                    fullWidth
                    onClick={() => navigate(-1)}
                    startIcon={<CategoryIcon />}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Box>
      </>
    );
  }

  return Content;
};

export default AddExpense;

import React, {useEffect, useState} from "react";
import {useGetSingleExpenseCategoryQuery, useUpdateExpenseCategoryMutation,} from "../../services/expenseCategoryApi";
import Spinner from "../../shared/Spinner";
import {useNavigate, useParams} from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {toast} from "react-toastify";
import {makeStyles} from "@mui/styles";
import {Box, Button, Grid, InputAdornment, TextField, Typography,} from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const EditExpenseCategory = () => {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    _id: "",
    expense_type: "",
  });
  const { _id, expense_type } = formData;
  let Content;
  const { data, isLoading, isError, error, isSuccess, message } =
    useGetSingleExpenseCategoryQuery(params.id);
  const [updateExpenseCategory, { uisLoading }] =
    useUpdateExpenseCategoryMutation();

  useEffect(() => {
    function fetchData() {
      if (isSuccess && data) {
        setFormData({
          _id: data._id,
          expense_type: data.expense_type,
        });
      }
    }
    fetchData();
  }, [isSuccess, data]);

  if (uisLoading) {
    return <Spinner />;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    await updateExpenseCategory({ _id, expense_type })
      .unwrap()
      .then((payload) => {
        toast.success(
          `Expense Category updated to ${JSON.stringify(
            payload.expense_type
          )} successfully`
        );
        navigate("/expensecategory");
      })
      .catch((error) =>
        toast.error(`Error updating Expense Category ${error.data.message}`)
      );
  };

  let updateInputExpenseCategory = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (isLoading) {
    return <Spinner />;
  }
  if (isError && error) {
    Content = (
      <div>
        <p>ExpenseCategory not found... {JSON.stringify(message)}</p>
      </div>
    );
  }
  if (isSuccess && data) {
    Content = (
      <>
        <Box className={classes.root}>
          <Grid
            style={{
              padding: "50px 5px 0 5px",
              width: 350,
              margin: "0 auto",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} mb={0} className={classes.items}>
                <CategoryIcon
                  color={"primary"}
                  style={{ width: "70px", height: "70px" }}
                />
              </Grid>
              <Grid item xs={12} mb={2} className={classes.items}>
                <Typography variant="h5" color="error">
                  Add Expense Category
                </Typography>
              </Grid>
            </Grid>
            <form onSubmit={onSubmit}>
              <Grid container spacing={1}>
                <Grid xs={12} sm={12} mb={1} item>
                  <TextField
                    name="expense_type"
                    type="text"
                    onChange={updateInputExpenseCategory}
                    label="Expense Category"
                    value={expense_type}
                    placeholder="Enter Expense Category"
                    fullWidth
                    required
                    variant={"outlined"}
                    InputProps={{
                      // classes: { input: classes.input1 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountCircleIcon color={"success"} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} mt={2} mb={1}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    size={"large"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<LockOpenIcon />}
                    style={{ marginBottom: "15px" }}
                  >
                    Submit
                  </Button>
                  <Button
                    size={"large"}
                    variant="contained"
                    color={"success"}
                    fullWidth
                    onClick={() => navigate(-1)}
                    startIcon={<CategoryIcon />}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Box>
      </>
    );
  }

  return Content;
};

export default EditExpenseCategory;

import {apiSlice} from "../app/apiSlice";

// getAllExpenseCategory,
// getSingleExpenseCategory,
// setExpenseCategory,
// updateExpenseCategory,
// deleteExpenseCategory,

export const expenseCategoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllExpenseCategory: builder.query({
      query: () => "/expensecategory",
      providesTags: ["ExpenseCategory"],
    }),
    getSingleExpenseCategory: builder.query({
      query: (id) => `/expensecategory/${id}`,
      providesTags: ["ExpenseCategory"],
    }),
    addExpenseCategory: builder.mutation({
      query: (expenseCategory) => ({
        url: "/expensecategory",
        method: "POST",
        body: expenseCategory,
      }),
      invalidatesTags: ["ExpenseCategory"],
    }),
    deleteExpenseCategory: builder.mutation({
      query: (id) => ({
        url: `/expensecategory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExpenseCategory"],
    }),
    updateExpenseCategory: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/expensecategory/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["ExpenseCategory"],
    }),
  }),
});

export const {
  useGetAllExpenseCategoryQuery,
  useGetSingleExpenseCategoryQuery,
  useAddExpenseCategoryMutation,
  useDeleteExpenseCategoryMutation,
  useUpdateExpenseCategoryMutation,
} = expenseCategoryApi;

import {apiSlice} from "../app/apiSlice";

export const reportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    currentStatement: builder.query({
      query: () => "/report",
    }),
  }),
});

export const { useCurrentStatementQuery } = reportApi;

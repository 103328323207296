import React, {useState} from "react";
import Spinner from "../../shared/Spinner";
import {useNavigate} from "react-router-dom";
import {Box, Button, Dialog, DialogActions, DialogContent, Typography,} from "@mui/material";
import {useDeleteContactMutation, useGetContactsQuery,} from "../../services/contactApi";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {toast} from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Visibility} from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";

const ContactManager = () => {
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              padding: "25px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "firstname",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastname",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile#",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "landline",
      label: "Landline#",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "office_address",
      label: "Office-Address",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "home_address",
      label: "Home-Address",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="View Contact">
                <IconButton
                  onClick={() =>
                    navigate("/contacts/view/" + tableMeta.rowData[0])
                  }
                >
                  <Visibility color={"primary"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Contact">
                <IconButton
                  onClick={() =>
                    navigate("/contacts/edit/" + tableMeta.rowData[0])
                  }
                >
                  <EditIcon color={"warning"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Contact">
                <IconButton
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        deleteContactFromDB(tableMeta.rowData[0]);
                      },
                    });
                  }}
                >
                  <DeleteForeverIcon color={"error"} />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => (
    <>
      <Tooltip title="Add Contact">
        <IconButton onClick={() => navigate("/contacts/add")}>
          <AddCircleIcon
            color={"warning"}
            sx={{ fontSize: { xs: 26, sm: 32 } }}
          />
        </IconButton>
      </Tooltip>
    </>
  );

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
    rowsPerPage: 10,
    resizableColumns: false,
    rowsPerPageOptions: [5, 10, 15, 25, 50, 100, 150],
    jumpToPage: true,
    showLastButton: true,
    elevation: 2,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total Per Page",
        displayRows: "of",
        jumpToPage: "Page",
      },
      body: {
        noMatch: "No ContactsModel to display please Add Contact",
      },
    },
    selectableRows: "none",
    customToolbar: () => <HeaderElements />,
  };
  let Content;
  const { data, isLoading, isError, error, isSuccess, message } =
    useGetContactsQuery();
  const [deleteContact, { disError, disSuccess, dmessage }] =
    useDeleteContactMutation();

  if (isSuccess && data) {
    Content = (
      <Box
        sx={{
          width: { xs: "98%", sm: "95%", md: "90%", lg: "85%", xl: "80%" },
          margin: "auto auto",
          marginTop: "1.5%",
          marginBottom: "2%",
        }}
      >
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                title={"ContactsModel Manager"}
                data={data}
                columns={columns}
                options={options}
            />
          </ThemeProvider>
        </CacheProvider>

        <Dialog open={confirmDialog.isOpen}>
          <DialogContent>
            <Typography variant="h6">{confirmDialog.title}</Typography>
            <Typography variant="subtitle2" color={"indigo"}>
              {confirmDialog.subTitle}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
            <Button
              variant={"contained"}
              color={"error"}
              onClick={confirmDialog.onConfirm}
              size={"small"}
            >
              Yes
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={() =>
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }
  if (isError && error) {
    Content = (
      <div>
        <p>No Contacts to show {JSON.stringify(message)}</p>
      </div>
    );
  }
  if (disSuccess) {
    toast.success("Contact deleted successfully");
  }
  if (disError) {
    toast.error("Something went wrong...");
    console.log(dmessage);
  }
  const deleteContactFromDB = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await deleteContact(id);
    toast.success("Successfully deleted contact");
  };

  return <>{Content}</>;
};

export default ContactManager;

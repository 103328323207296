import * as React from "react";
import {apiSlice} from "../../app/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {auth_reset} from "../../app/authSlice";
import {toast} from "react-toastify";
import {NavLink, useNavigate} from "react-router-dom";
import UserIMG from "../../asset/img/user.png";
import LogoIMG from "../../asset/img/Logo.png";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import {Hidden} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleIcon from "@mui/icons-material/People";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const Navbar = () => {
  const theme = createTheme({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.auth);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const goHome = () => {
    handleCloseNavMenu();
    navigate("/");
  };
  const goContacts = () => {
    handleCloseNavMenu();
    navigate("/contacts");
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    navigate("/");
  };

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(auth_reset());
    dispatch(apiSlice.util.resetApiState());
    setAnchorElUser(null);
    toast.success("Successfully logged out");
    navigate("/login");
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        color="primary"
        sx={{ bgcolor: "primary.dark" }}
      >
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Hidden lgDown>
              <Avatar
                alt={data.firstname}
                src={LogoIMG}
                style={{ marginRight: "10px" }}
              />
              <Box sx={{ flexGrow: 1 }} style={{ marginLeft: 8 }}>
                <Typography variant="h5">Home Expense</Typography>
              </Box>
            </Hidden>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon style={{ marginRight: 10 }} />
                <Box sx={{ flexGrow: 1 }} style={{ marginLeft: 8 }}>
                  <Typography variant="h6"> Home Expense</Typography>
                </Box>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={goHome}>
                  <Typography textAlign="center">Income</Typography>
                </MenuItem>
                <MenuItem onClick={goHome}>
                  <Typography textAlign="center">IncomeCategory</Typography>
                </MenuItem>
                <MenuItem onClick={goHome}>
                  <Typography textAlign="center">Expense</Typography>
                </MenuItem>
                <MenuItem onClick={goHome}>
                  <Typography textAlign="center">ExpenseCategory</Typography>
                </MenuItem>
                <MenuItem onClick={goHome}>
                  <Typography textAlign="center">Members</Typography>
                </MenuItem>
                <MenuItem onClick={goContacts}>
                  <Typography textAlign="center">Contacts</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Hidden lgDown>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Button
                  color="inherit"
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/"
                  startIcon={<AssignmentIndIcon />}
                >
                  Dashboard
                </Button>

                <Button
                  color="inherit"
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/income"
                  startIcon={<AddShoppingCartIcon />}
                >
                  Income
                </Button>
                <Button
                  color="inherit"
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/incomecategory"
                  startIcon={<CategoryIcon />}
                >
                  IncomeCategory
                </Button>
                <Button
                  color="inherit"
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/expense"
                  startIcon={<ShoppingCartCheckoutIcon />}
                >
                  Expense
                </Button>
                <Button
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/expensecategory"
                  startIcon={<CategoryIcon />}
                >
                  ExpenseCategory
                </Button>
                <Button
                  color="inherit"
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/members"
                  startIcon={<PeopleIcon />}
                >
                  Members
                </Button>
                <Button
                  color="inherit"
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/contacts"
                  startIcon={<PermContactCalendarIcon />}
                >
                  Contacts
                </Button>
              </Box>
            </Hidden>
            {data.firstname && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={data.firstname} src={UserIMG} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Dashboard</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => logout()}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;

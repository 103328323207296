import {CircularProgress, Grid} from "@mui/material";

const Spinner = () => {
    return (
      <Grid
        style={{
          padding: "150px 5px 0 5px",
          width: 350,
          margin: "0 auto",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} align="center" mb={2}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  
  export default Spinner;

import {apiSlice} from "../app/apiSlice";

// getAllExpense,
// getSingleExpense,
// addExpense,
// updateExpense,
// deleteExpense,

export const expenseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllExpense: builder.query({
      query: () => "/expense",
      providesTags: ["Expense"],
    }),
    getSingleExpense: builder.query({
      query: (id) => `/expense/${id}`,
      providesTags: ["Expense"],
    }),
    addExpense: builder.mutation({
      query: (expense) => ({
        url: "/expense",
        method: "POST",
        body: expense,
      }),
      invalidatesTags: ["Expense"],
    }),
    deleteExpense: builder.mutation({
      query: (id) => ({
        url: `/expense/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Expense"],
    }),
    updateExpense: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/expense/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Expense"],
    }),
  }),
});

export const {
  useGetAllExpenseQuery,
  useGetSingleExpenseQuery,
  useAddExpenseMutation,
  useDeleteExpenseMutation,
  useUpdateExpenseMutation,
} = expenseApi;

import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  _id: null,
  firstname: null,
  lastname: null,
  email: null,
  fullname: null,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state._id = action.payload._id;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.fullname = action.payload.fullname;
      state.token = action.payload.token;
    },
    auth_reset: (state) => {
      state._id = null;
      state.firstname = null;
      state.lastname = null;
      state.email = null;
      state.fullname = null;
      state.token = null;
    },
    defaultState: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, defaultState, auth_reset } = authSlice.actions;

export default authSlice.reducer;

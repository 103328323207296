import {apiSlice} from "../app/apiSlice";

// getAllMember,
// getSingleMember,
// setMember,
// updateMember,
// deleteMember,

export const membersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMembers: builder.query({
      query: () => "/members",
      providesTags: ["Member"],
    }),
    getSingleMember: builder.query({
      query: (id) => `/members/${id}`,
      providesTags: ["Member"],
    }),
    addMember: builder.mutation({
      query: (member) => ({
        url: "/members",
        method: "POST",
        body: member,
      }),
      invalidatesTags: ["Member"],
    }),
    deleteMember: builder.mutation({
      query: (id) => ({
        url: `/members/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Member"],
    }),
    updateMember: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/members/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Member"],
    }),
  }),
});

export const {
  useGetAllMembersQuery,
  useGetSingleMemberQuery,
  useAddMemberMutation,
  useDeleteMemberMutation,
  useUpdateMemberMutation,
} = membersApi;

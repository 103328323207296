import React from "react";
import {useGetSingleExpenseCategoryQuery} from "../../services/expenseCategoryApi";
import Spinner from "../../shared/Spinner";
import {useParams} from "react-router-dom";

const ViewExpenseCategory = () => {
  const params = useParams();
  let Content;
  const { data, isLoading, isError, error, isSuccess, message } =
    useGetSingleExpenseCategoryQuery(params.id);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError && error) {
    Content = (
      <div>
        <p>ExpenseCategory not found... {JSON.stringify(message)}</p>
      </div>
    );
  }
  if (isSuccess && data) {
    Content = <div>{JSON.stringify(data)}</div>;
  }

  return Content;
};

export default ViewExpenseCategory;

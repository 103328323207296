import {makeStyles, useTheme} from "@mui/styles";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useAddContactMutation} from "../../services/contactApi";
import {Box, Button, Grid, InputAdornment, TextField, Typography,} from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
    marginRight: "15%",
    marginLeft: "15%",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  grid_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddContact = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.only("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.only("xs"));
  const matchesXL = useMediaQuery(theme.breakpoints.only("xl"));
  const matchesLG = useMediaQuery(theme.breakpoints.only("lg"));
  const dynamicStyles = {
    ...(matchesSM && {
      marginRight: "5%",
      marginLeft: "5%",
    }),
    ...(matchesXS && {
      marginRight: "5%",
      marginLeft: "5%",
    }),
    ...(matchesMD && {
      marginRight: "5%",
      marginLeft: "5%",
    }),
    ...(matchesXL && {
      marginRight: "25%",
      marginLeft: "25%",
    }),
    ...(matchesLG && {
      marginRight: "20%",
      marginLeft: "20%",
    }),
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    gender: "",
    mobile: "",
    landline: "",
    office_address: "",
    home_address: "",
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3WEmfJCME77ZGymWrlJkXRv5bWg9QQmQEzw&usqp=CAU",
  });
  const {
    firstname,
    lastname,
    email,
    gender,
    mobile,
    landline,
    office_address,
    home_address,
    avatar,
  } = formData;
  const [addContacts] = useAddContactMutation();

  const onSubmit = async (e) => {
    e.preventDefault();
    await addContacts({
      firstname,
      lastname,
      email,
      gender,
      mobile,
      landline,
      office_address,
      home_address,
      avatar,
    })
      .unwrap()
      .then((payload) => {
        toast.success(`Contact added successfully`);
        navigate("/contacts");
      })
      .catch((error) =>
        toast.error(`Error adding Contact : ${error.data.message}`)
      );
    navigate("/contacts");
  };

  const onChange = (e) => {
    // simple
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Box className={classes.root} sx={{ ...dynamicStyles }}>
        <Grid>
          <Grid container spacing={1} className={classes.items}>
            <Grid item xs={12} sm={12} mb={0} className={classes.items}>
              <HowToRegIcon
                color={"primary"}
                style={{ width: "70px", height: "70px" }}
              />
            </Grid>
            <Grid item xs={12} mb={2} className={classes.items}>
              <Typography variant="h5" color="error">
                Add Contact
              </Typography>
            </Grid>
          </Grid>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2} className={classes.grid_container}>
              <Grid xs={12} sm={6} xl={4} lg={4} md={4} mb={1} item>
                <TextField
                  name="firstname"
                  type="text"
                  onChange={onChange}
                  label="First Name"
                  value={formData.firstname}
                  placeholder="Enter First Name"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} xl={4} lg={4} md={4} mb={1} item>
                <TextField
                  name="lastname"
                  type="text"
                  onChange={onChange}
                  label="Last Name"
                  value={formData.lastname}
                  placeholder="Enter Last Name"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} xl={4} lg={4} md={4} mb={1} item>
                <TextField
                  name="email"
                  type="text"
                  onChange={onChange}
                  label="Email"
                  value={formData.email}
                  placeholder="Enter Email"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} xl={4} lg={4} md={4} mb={1} item>
                <TextField
                  name="gender"
                  type="text"
                  onChange={onChange}
                  label="Gender"
                  value={formData.gender}
                  placeholder="Enter Gender"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} xl={4} lg={4} md={4} mb={1} item>
                <TextField
                  name="mobile"
                  type="text"
                  onChange={onChange}
                  label="Mobile"
                  value={formData.mobile}
                  placeholder="Enter Mobile"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} xl={4} lg={4} md={4} mb={1} item>
                <TextField
                  name="landline"
                  type="text"
                  onChange={onChange}
                  label="Landline"
                  value={formData.landline}
                  placeholder="Enter Landline"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} mb={1} item>
                <TextField
                  name="office_address"
                  type="text"
                  onChange={onChange}
                  label="Office Address"
                  value={formData.office_address}
                  placeholder="Enter Office Address"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} mb={1} item>
                <TextField
                  name="home_address"
                  type="text"
                  onChange={onChange}
                  label="Home Address"
                  value={formData.home_address}
                  placeholder="Enter Home Address"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2} mb={1}>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  size={"large"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<LockOpenIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size={"large"}
                  variant="contained"
                  color={"success"}
                  fullWidth
                  onClick={() => navigate(-1)}
                  startIcon={<HowToRegIcon />}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Box>
    </>
  );
};

export default AddContact;

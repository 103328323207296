import React from "react";
import {Hidden, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Box} from "@mui/system";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
    marginBottom: "50px",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <Typography variant="h7" color="#145A32">
          Home Expense React App with Material UI{" "}
          <Typography variant="h7" color="#FFC300">
            @2022 All right reserved
          </Typography>
        </Typography>
        <Typography variant="h7" color="primary">
          Developed by{" "}
          <Typography variant="h7" color="#BA4A00">
            Shankar Dakolia
          </Typography>{" "}
          assisted by{" "}
          <Typography variant="h7" color="#BA4A00">
            Juned Davda
          </Typography>
        </Typography>
      </Hidden>
      <Hidden smUp>
        <Typography variant="h7" color="#145A32">
          Home Expense React App with Material UI
        </Typography>
        <Typography variant="h7" color="#FFC300">
          @2022 All right reserved
        </Typography>
        <Typography variant="h7" color="primary">
          Developed by{" "}
          <Typography variant="h7" color="#BA4A00">
            Shankar Dakolia
          </Typography>
        </Typography>
        <Typography variant="h7" color="primary">
          Assisted by{" "}
          <Typography variant="h7" color="#BA4A00">
            Juned Davda
          </Typography>
        </Typography>
      </Hidden>
    </Box>
  );
};

export default Footer;

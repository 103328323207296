import React from "react";
import {flattenObject} from "../../utils/sharedFunctions";
import {useGetSingleIncomeQuery} from "../../services/incomeApi";
import Spinner from "../../shared/Spinner";
import {useParams} from "react-router-dom";

const ViewIncome = () => {
  const params = useParams();
  let Content;
  const { data, isLoading, isError, error, isSuccess, message } =
    useGetSingleIncomeQuery(params.id);

  if (isLoading) {
    return <Spinner />;
  }
  if (isError && error) {
    Content = (
      <div>
        <p>Income not found... {JSON.stringify(message)}</p>
      </div>
    );
  }
  if (isSuccess && data) {
    let displayData = flattenObject(data);
    Content = <div>{JSON.stringify(displayData)}</div>;
  }

  return Content;
};

export default ViewIncome;

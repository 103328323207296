import {apiSlice} from "../app/apiSlice";

// getAllIncome,
// getSingleIncome,
// addIncome,
// updateIncome,
// deleteIncome,

export const incomeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllIncome: builder.query({
      query: () => "/income",
      providesTags: ["Income"],
    }),
    getSingleIncome: builder.query({
      query: (id) => `/income/${id}`,
      providesTags: ["Income"],
    }),
    addIncome: builder.mutation({
      query: (income) => ({
        url: "/income",
        method: "POST",
        body: income,
      }),
      invalidatesTags: ["Income"],
    }),
    deleteIncome: builder.mutation({
      query: (id) => ({
        url: `/income/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Income"],
    }),
    updateIncome: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/income/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Income"],
    }),
  }),
});

export const {
  useGetAllIncomeQuery,
  useGetSingleIncomeQuery,
  useAddIncomeMutation,
  useDeleteIncomeMutation,
  useUpdateIncomeMutation,
} = incomeApi;

import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {Box, Typography} from "@mui/material";
import moment from "moment";
import {useCurrentStatementQuery} from "../../services/reportApi";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "150px",
    color: "red",
  },
  statement: {
    color: "#008080",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const stateData = useSelector((state) => state.auth);
  const { data, isSuccess } = useCurrentStatementQuery();
  // const current = new Date();
  // const date =
  //   current.getDate() + "/" + current.getMonth() + "/" + current.getFullYear();

  return (
    <>
      {isSuccess && data && (
        <Box className={classes.root}>
          <Typography variant="h6">
            Welcome Back : {stateData.fullname}
          </Typography>
          <br />
          <Typography variant="h6" color="secondary">
            Today's Date : {moment().format("LLLL")}
          </Typography>
          <br />
          <Typography variant="h6" className={classes.statement}>
            Total Income : {JSON.stringify(data.Total_Income)}
          </Typography>
          <Typography variant="h6" className={classes.statement}>
            Total Expense : {JSON.stringify(data.Total_Expense)}
          </Typography>
          <Typography variant="h6" className={classes.statement}>
            Current Balance : {JSON.stringify(data.Current_Balance)}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Dashboard;

import {makeStyles} from "@mui/styles";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Box, Button, Grid, IconButton, InputAdornment, TextField, Typography,} from "@mui/material";
import Spinner from "../../shared/Spinner";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {setUser} from "../../app/authSlice";
import {useLoginUserMutation} from "../../services/authApi";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  if (user.token) {
    navigate("/");
  }
  const [loginUser, { data, isLoading, isError, error, isSuccess }] =
    useLoginUserMutation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const { email, password, showPassword } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    await loginUser({ email, password });
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClickShowPassword = () => {
    setFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const signup = () => {
    navigate("/register");
  };

  if (isSuccess && data) {
    localStorage.setItem("token", data.token);
    const user = {
      _id: data._id,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      fullname: data.firstname + " " + data.lastname,
      token: data.token,
    };

    dispatch(setUser(user));
    toast.success("Successfully logged in !");
    navigate("/");
  }
  if (isError && error) {
    const errMessage = error.data?.message;
    if (errMessage === undefined) {
      console.log("Something went wrong...");
      toast.error("Something went wrong...");
    }
    toast.error(errMessage);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Box className={classes.root}>
        <Grid
          style={{
            padding: "50px 5px 0 5px",
            width: 350,
            margin: "0 auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} mb={0} className={classes.items}>
              <HowToRegIcon
                color={"primary"}
                style={{ width: "70px", height: "70px" }}
              />
            </Grid>
            <Grid item xs={12} mb={2} className={classes.items}>
              <Typography variant="h5" color="error">
                Login
              </Typography>
            </Grid>
          </Grid>
          <form onSubmit={onSubmit}>
            <Grid container spacing={1}>
              <Grid xs={12} sm={12} mb={1} item>
                <TextField
                  name="email"
                  type="email"
                  onChange={onChange}
                  label="Email"
                  value={email}
                  placeholder="Enter email"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <AlternateEmailIcon color={"success"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} mb={1} item>
                <TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={onChange}
                  label="Password"
                  value={password}
                  placeholder="Enter Password"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility color={"error"} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2} mb={1}>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  size={"large"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<LockOpenIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Login
                </Button>
                <Button
                  size={"large"}
                  variant="contained"
                  color={"success"}
                  fullWidth
                  onClick={signup}
                  startIcon={<HowToRegIcon />}
                >
                  Signup
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Box>
    </>
  );
};

export default Login;

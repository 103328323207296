import "./App.css";
import "./asset/css/style.css";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/layout/Dashboard";
import BaseTheme from "./shared/BaseTheme";
import {CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/styles";
import {ToastContainer} from "react-toastify";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {useGetUserQuery} from "./services/authApi";
import {setUser} from "./app/authSlice";
import ContactManager from "./components/contacts/ContactManager";
import ViewContact from "./components/contacts/ViewContact";
import AddContact from "./components/contacts/AddContact";
import EditContact from "./components/contacts/EditContact";
import Footer from "./components/layout/Footer";
import MemberManager from "./components/members/MemberManager";
import ViewMember from "./components/members/ViewMember";
import EditMember from "./components/members/EditMember";
import AddMember from "./components/members/AddMember";
import IncomeCategoryManager from "./components/income/IncomeCategoryManager";
import ViewIncomeCategory from "./components/income/ViewIncomeCategory";
import EditIncomeCategory from "./components/income/EditIncomeCategory";
import AddIncomeCategory from "./components/income/AddIncomeCategory";
import IncomeManager from "./components/income/IncomeManager";
import ViewIncome from "./components/income/ViewIncome";
import EditIncome from "./components/income/EditIncome";
import AddIncome from "./components/income/AddIncome";
import ExpenseCategoryManager from "./components/expense/ExpenseCategoryManager";
import ViewExpenseCategory from "./components/expense/ViewExpenseCategory";
import EditExpenseCategory from "./components/expense/EditExpenseCategory";
import AddExpenseCategory from "./components/expense/AddExpenseCategory";
import ExpenseManager from "./components/expense/ExpenseManager";
import ViewExpense from "./components/expense/ViewExpense";
import EditExpense from "./components/expense/EditExpense";
import AddExpense from "./components/expense/AddExpense";

const ProtectedRoute = ({ redirectPath = "/login" }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} />;
  }
  return <Outlet />;
};

function App() {
  const dispatch = useDispatch();
  const { data, isSuccess, isError, error } = useGetUserQuery();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (isSuccess && data) {
        const _token = localStorage.getItem("token");
        const user = {
          _id: data._id,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          fullname: data.firstname + " " + data.lastname,
          token: _token,
        };
        dispatch(setUser(user));
      }
      if (isError && error) {
        localStorage.removeItem("token");
      }
    }
  });

  return (
    <>
      <ThemeProvider theme={BaseTheme}>
        <CssBaseline />
        <ToastContainer position="top-right" theme="colored" autoClose={900} />

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Dashboard />} />
            {/* ContactsModel Routes */}
            <Route path="/contacts" element={<ContactManager />} />
            <Route path="/contacts/view/:id" element={<ViewContact />} />
            <Route path="/contacts/edit/:id" element={<EditContact />} />
            <Route path="/contacts/add" element={<AddContact />} />
            {/* Members Routes */}
            <Route path="/members" element={<MemberManager />} />
            <Route path="/members/view/:id" element={<ViewMember />} />
            <Route path="/members/edit/:id" element={<EditMember />} />
            <Route path="/members/add" element={<AddMember />} />
            {/* Income Category Routes */}
            <Route path="/incomecategory" element={<IncomeCategoryManager />} />
            <Route
              path="/incomecategory/view/:id"
              element={<ViewIncomeCategory />}
            />
            <Route
              path="/incomecategory/edit/:id"
              element={<EditIncomeCategory />}
            />
            <Route path="/incomecategory/add" element={<AddIncomeCategory />} />
            {/* Income Routes */}
            <Route path="/income" element={<IncomeManager />} />
            <Route path="/income/view/:id" element={<ViewIncome />} />
            <Route path="/income/edit/:id" element={<EditIncome />} />
            <Route path="/income/add" element={<AddIncome />} />
            {/* Expense Category Routes */}
            <Route
              path="/expensecategory"
              element={<ExpenseCategoryManager />}
            />
            <Route
              path="/expensecategory/view/:id"
              element={<ViewExpenseCategory />}
            />
            <Route
              path="/expensecategory/edit/:id"
              element={<EditExpenseCategory />}
            />
            <Route
              path="/expensecategory/add"
              element={<AddExpenseCategory />}
            />
            {/* Expense Routes */}
            <Route path="/expense" element={<ExpenseManager />} />
            <Route path="/expense/view/:id" element={<ViewExpense />} />
            <Route path="/expense/edit/:id" element={<EditExpense />} />
            <Route path="/expense/add" element={<AddExpense />} />
          </Route>
        </Routes>
      </ThemeProvider>
      <Footer />
    </>
  );
}

export default App;
